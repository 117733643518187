<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ $t('Confirm') }}</div>
    </template>
    <div class="p-formgrid p-grid">
      <div v-if="items?.length" class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-d-flex p-ai-center p-mb-4">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
<!--          <span>{{ $t('Are you sure you want to proceed?') }}</span>-->
          <div>Warning! Possible duplicates found:</div>
        </div>
        <div class="table-wrapper">
          <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows">
            <thead class="p-datatable-thead">
            <template></template>
            <tr>
              <th>{{ $t('Type') }}</th>
              <th v-if="thereIsCompanyInDublicates">{{ $t('Company name') }}</th>
              <th>{{ $t('First name') }}</th>
              <th>{{ $t('Last name') }}</th>
              <th>{{ $t('Phone') }}</th>
              <th>{{ $t('Email') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody class="p-datatable-tbody">
            <tr v-for="item of items" :key="item.id">
              <td>
                <span v-if="item.type">{{ CustomerTypes.find(t => t.type === item.type)?.label[$i18n.locale] }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td v-if="thereIsCompanyInDublicates">
                <span v-if="item.type === 2 && item.company_name">{{ item.company_name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.first_name">{{ item.first_name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.last_name">{{ item.last_name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.type === 1 && item.phone_code && item.phone_number">+{{ item.phone_code }} {{ item.phone_number }}</span>
                <span v-else-if="item.type === 1 && item.full_phone_number">+{{ item.full_phone_number }}</span>
                <span v-else-if="item.type === 2 && item.company_phone_code && item.company_phone_number">+{{ item.company_phone_code }} {{ item.company_phone_number }}</span>
                <span v-else-if="item.type === 2 && item.company_full_phone_number">+{{ item.company_full_phone_number }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.type === 1 && item.email">{{ item.email }}</span>
                <span v-else-if="item.type === 2 && item.company_email">{{ item.company_email }}</span>
                <span v-else-if="item.type === 2 && item.invoice_email">{{ item.invoice_email }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <Button :label="$t('Select')" @click="selectCustomer(item)" class="p-button-raised" style="width: auto;"></Button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Close')" icon="pi pi-times" class="p-button-text" @click="close"/>
    </template>
  </Modal>
</template>

<script>
import CustomerTypes from "@/translations/CustomerTypes";

export default {
  name: 'CustomerExistsModal',
  emits: ['close', 'confirm', 'select-existing-customer'],
  props: {
    items: Array,
    data: String,
    header: null,
    footer: null,
    visible: Boolean,
    style: Object,
    userId: {
      type: [ Number, null ],
      default: null
    }
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.createNewAnyway = false
      }
    }
  },
  data() {
    return {
      createNewAnyway: false,
      CustomerTypes
    }
  },
  methods: {
    selectCustomer(customer) {
      this.$emit('select-existing-customer', customer)
      this.close()
    },
    // createNew() {
    //   this.$emit('create-new-anyway')
    //   this.close()
    // },
    close() {
      this.$emit('close')
      this.submitted = false
    },
    confirm() {
      this.$emit('confirm')
    }
  },
  computed: {
    // computedPrivatePersons() {
    //   if (this.items?.length) {
    //     const privatePersons = this.items
    //   } else {
    //     return false
    //   }
    // },
    thereIsCompanyInDublicates() {
      return !!this.items?.find(i => i.type === 2)
    },
    computedWindowSize() {
      // if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
      //   return {width: '85%'}
      // } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
      //   return {width: '550px'}
      // } else {
      //   return {width: '650px'}
      // }
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '35px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '95%', maxWidth: '750px', marginTop: '35px'}
      } else {
        return {position: 'absolute', top: 0, width: '750px', marginTop: '25px'}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-wrapper {
  overflow-y: auto;
}

.p-datatable {
  width: 100%;
  border-collapse: collapse;
}
</style>